/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 29%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 700;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 19px;
  opacity: 1;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 10px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.react-multiple-carousel__arrow:hover {
  background-color: rgba(196, 140, 140, 0.4)!important; 
}
.item h4{
  margin-top: 20px!important;
}
.skill {
  padding: 0 0 50px 0;
  position: relative;
  background-image: url('./assets/img/banner-bg2.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.skill-bx {
  border: 1px solid #fff;
  background: #151515;
  border-radius: 1px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill img, svg {
  max-width: fit-content;
  vertical-align: middle;
}
.skill-slider .item img {
  
  max-width: 100px!important;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  margin-top: 0;
  padding: 0px 0 0px 0;
  background-image: url(../src/assets/img/banner-bg2.png);
  background-position: top center;
  background-size: contain;
  background-repeat: repeat;

}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(196, 140, 140, 0.4); 
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: rgb(1,1,1);
    background: linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(255,255,255,0.15870098039215685) 100%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  border: solid 1px #fff;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(217,15,15,0.34637605042016806) 100%);  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 h5 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #fff!important;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/

.contact {
  background-image: url(../src/assets/img/banner-bg2.png);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 1px;
  color: #fff;
  margin: 0 0 10px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}
.proj-imgbx {
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  margin-bottom: 24px;
}
/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url(../src/assets/img/banner-bg.png)!important;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 30%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
@media (min-width: 576px){
.col-sm-6 {
    flex: 0 0 auto!important;
    width: 50%!important;
}
}
@media only screen and (max-width: 700px){
.col-md-6{
  margin-left: 20px;
  }

.mt-4 {
    margin-top: 1.5rem!important;
    margin-left: 20px!important;
}
  
  .row>* {
    flex-shrink: 0;
    width: 93%!important;
  }
  .banner {
    margin-top: 0;
    padding: 150px 0 100px 0;
  }
  .item h4{
    margin-top: 20px!important;
  }
  .row {
    margin-left: 0px!important;
}
  .bannerlogo {
    margin-top: -40px!important;
}
  .project p {
   margin-top: 30px;
    width: 75%;
    margin-bottom: 100px;
}
.project .nav.nav-pills {
    width: 100%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}
img, svg {
  width: 300px!important;
  vertical-align: middle;
}
.navbar-toggler {
  margin-right: 30px;
}
.banner h1 {
  font-size: 40px;
}
.col-12 {
  margin-top: 50px!important;
  flex: 0 0 auto;
  width: 100%;
}
.banner button {
  line-height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.navbar-collapse {
  line-height: 40px;
  margin-right: 20px;
  padding: 30px;
  background-color: black;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  margin-bottom: 10px;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 7px 34px;
}
.social-icon {
  margin-bottom: 30px;
  margin-top: 30px;
  display: inline-block;
  margin-left: 22px;
}
.social-icon a img {
  max-width: 20px!important;
}

.navbar-text button:hover {
  font-weight: 700;
  background-color: #fff!important;
  color: #000;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	transition: 0.3s ease-in-out;
}
.carousel-caption{
  padding-top: 0px;
  padding-bottom: 0px;
}
}
.banner2{
  margin-top: 0;
  padding: 50px 0 50px 0;
  background-image: url('./assets/img/banner-bg2.png');
  background-position: top center;
  background-size:auto;
  background-repeat: no-repeat;
}
.banner2 h2{
  
  width: 100%;
  max-height: 50px;
  background: #121212;
  color: #fff;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}
.bannerlogo {
  display: flex;
  max-width: 500px!important;
  margin: auto;
  object-fit: contain;
}
.carousel-inner {
  border: solid 2px #ffffff;
  filter: brightness(1)!important;
  margin: auto!important;
  position: relative;
  width: 50%!important;
  overflow: hidden;
}
.w-100 {
  filter: brightness(0.5);
}
.project p {
  color: #fff;
}
@media only screen and (max-width: 700px){
.campain {
  display: none;
  }
  .grid-container {
    gap: 1px!important;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

.grid-item {
  display: flex;
  flex-direction: column;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin: auto;
  }
  .grid-item {
    margin: auto!important;
  }
  .videoGrid{
    grid-template-columns: repeat(1, 1fr)!important;  
  }
  .project .nav.nav-pills .nav-link {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(12, 1fr);
  }
  .project{
    background-image: url('./assets/img/banner-bg2.png');

  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, 1fr);
  }
}
.grid-item {
  margin: auto!important;
  
}
.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.video-container {
  position: relative;
}

.video-container img {
  width: 100%;
  height: auto;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: none;
  color: #fff;
  font-size: 3rem;
  opacity: 0.8;
  cursor: pointer;
}

.play-button:hover {
  opacity: 1;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-modal video {
  max-width: 90%;
  max-height: 90%;
}

/* Estilos oscuros */
body {
  background-color: #222;
  color: #fff;
}

.video-container {
  background-color: #333;
}

.play-button {
  border: 2px solid #fff;
}

.play-button:hover {
  background-color: #fff;
  color: #333;
}

.links{
  color: white;
}
a{
  color: #fff!important;
  text-decoration: none!important;

}
.video-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-card {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.video-card img {
  width: 100%;
  margin-bottom: 0.5rem;
}

.video-card h3 {
  margin-bottom: 0.5rem;
}

.video-card a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .video-card {
    padding: 0.5rem;
  }

  .video-card h3 {
    font-size: 1.2rem;
  }

  .video-card a {
    font-size: 1.2rem;
  }
 
}
.playBtn a:hover {
  color: red!important;
}
@media (max-width: 768px) {
.grid-item img{
    max-height: 379px;
    margin: auto!important;
    margin-top: 0px!important;
}
}
